.card-body {
  padding: 0.5rem;
}

.accent-title {
  font-family: "Rubik Bubbles", sans-serif;
  font-family: "Modak", sans-serif;
  /* font-family: "Bowlby One SC", sans-serif; */
  /* font-family: "Titan One", sans-serif; */

  -webkit-text-stroke: 2px #181b26;
  text-stroke: 2px #181b26;
  text-shadow: 5px 5px 0px #181b26;
  font-size: 3.5rem;
  transform: skew(-14deg);
}

.dots-font {
  font-family: "DotGothic16", sans-serif;
}

.bg-yellow {
  /* background-color: yellow; */
}

.bg-gray {
  /* background-color: #e2e2e2; */
}

.fusen1 {
  background-color: #74aacf;
}
.fusen2 {
  /* background-color: #87d0b9; */
  background-color: #88b0cd;
}
.fusen3 {
  /* background-color: #e8e8e8; */
}
.fusen4 {
  /* background-color: #d0af87; */
  background-color: #a2b8c9;
}

.border-color-gray {
  border-color: #bdbdbd;
}
.shadow-center {
  box-shadow: 2px 2px 1rem rgba(35, 35, 35, 0.3);
}

.input-no-focus {
  pointer-events: none;
}

.list-border {
  border-left: 3px solid;
  border-top: 3px solid;
  padding-left: 5px;
  border-radius: 14px 0 0 0;
}

.rotated-text {
  transform: rotate(40deg);
}


/* loader */

.container_SevMini {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2c2d42ad;
  z-index: 500;
  height: 100%;
  width: 100%;
}

.Ghost {
  transform: translate(0px, -25px);
  z-index: -1;
  animation: opacidad 4s infinite ease-in-out;
}

@keyframes opacidad {
  0% {
    opacity: 1;
    scale: 1;
  }

  50% {
    opacity: 0.5;
    scale: 0.9;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes estroboscopico {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rebote {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes estroboscopico1 {
  0%,
  50%,
  100% {
    fill: rgb(255, 95, 74);
  }

  25%,
  75% {
    fill: rgb(16, 53, 115);
  }
}

@keyframes estroboscopico2 {
  0%,
  50%,
  100% {
    fill: #17e300;
  }

  25%,
  75% {
    fill: #17e300b4;
  }
}

.SevMini {
  animation: rebote 4s infinite ease-in-out;
}

#strobe_led1 {
  animation: estroboscopico 0.5s infinite;
}

#strobe_color1 {
  animation: estroboscopico2 0.8s infinite;
}

#strobe_color3 {
  animation: estroboscopico1 0.8s infinite;
  animation-delay: 3s;
}

  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .sbar::-webkit-scrollbar {
      width: 8px;
  }
    
  .sbar::-webkit-scrollbar-thumb {
      background-color: hsl(var(--b2) / var(--tw-bg-opacity));
      border-radius: 100px;
      border: 1px solid hsl(var(--bc) / var(--tw-bg-opacity));
      width: 7px;
  }

  .sbar::-webkit-scrollbar-track {
    background-color: hsl(var(--b1) / var(--tw-bg-opacity));
    border-radius: 100px;
    border: 1px solid hsl(var(--b1) / var(--tw-bg-opacity));
}
